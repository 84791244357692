
import { SignedUrlFileCategory } from '@vc-workspace/utils-constants';
import { getGAClientId } from '@vc-workspace/utils-ga';
import { Category, Shop, SignedUrlSchema } from '@vc-workspace/utils-schemas';
import axios from 'axios';

export type SearchSuggestions = {
  siasCount: number,
  suggestions: string[],
  sellers: Shop[]
}

interface SignedUrlRequestPayload {
  metadata: {
    itemUsId: string
  },
  files: {
    fileName: string,
    fileCategory: SignedUrlFileCategory,
    extension: string,
    ttlMinutes: number,
    entityId?: string,
    entityType?: "CUSTOMER" | undefined
  }[]
}

interface SignedUrlResponse {
  urls: SignedUrlSchema[]
}

interface SingleObjectSignedUrlRequestPayload {
  metadata: {
    itemUsId: string
  },
  file: {
    fileName: string,
    fileCategory: SignedUrlFileCategory,
    extension: string,
    ttlMinutes: number,
    entityId?: string,
    entityType?: "CUSTOMER" | undefined
  }
}

export function fetchAllCategories() {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/categories`
  }).then((res: any) => {

    if(!res.data.categories) {
      return [];
    }

    return res.data.categories.filter((item: any) => item.hasMinProduct);
    
  })
}

export function fetchPinCodeDetails(pinCode: string) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/pinCodes`,
    params: {
      pinCode
    }
  }).then((res: any) => res.data.pinCode)
}

export async function getSearchSuggestions(searchedText: string, version?: string) {

  const visitorId = await getGAClientId();
  
  const res = await axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/products/search`,
    data: {
      nameQuery: searchedText,
      visitorId: visitorId || undefined,
      v: version
    }
  });
  return res.data;
}

export async function getGlobalSearchSuggestions(searchedText: string, version?: string) {

  const visitorId = await getGAClientId();

  const res = await axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/mall/products/search`,
    data: {
      nameQuery: searchedText,
      visitorId: visitorId || undefined,
      v: version
    }
  });
  return res.data;
}

export function sendAppLinkOnNumber(phone: string) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/support/requestDemo`,
    data: {
      phoneDialCode: '+91',
      phone
    }
  })
}

export function getSupportedISDCodes() {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/places/isdCodes`
  }).then((res: any) => res.data.countries)
}

export function fetchCuratedCategories() {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/categories/curated`
  }).then((res: any) => res.data.curatedCategories || []).catch(() => [])
}

export async function uploadMediaToS3(signedUrl: string, fileObj: any) {

  return axios({
    method: 'put',
    url: signedUrl,
    data: fileObj,
    headers: {},
  });
  
}

export function getSignedUrls(payload: SignedUrlRequestPayload): Promise<SignedUrlResponse> {

  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/content/marketSignedUrls`,
    data: payload
  }).then(res => res.data).catch(() => ({urls: []}));
}

export async function uploadMedia(signedUrlPayload: SingleObjectSignedUrlRequestPayload, fileObj: any): Promise<SignedUrlSchema> {

  try {
    const signedUrlResponse = await getSignedUrls({
      metadata: signedUrlPayload.metadata,
      files: [signedUrlPayload.file]
    });

    await uploadMediaToS3(signedUrlResponse.urls[0].signedUrl, fileObj);

    return signedUrlResponse.urls[0];
  } catch (err: any) {
    return err?.response?.data?.message || "Something went wrong. Please try after some time";
  }
}