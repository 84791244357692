import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { Typography } from '@vc-workspace/utils-typography';
import ScaleLoader from "react-spinners/ScaleLoader";
import { sendOTP } from '@vc-workspace/data-adapter';
import { containerStyles, contentContainerStyles, inputStyles, sendOTPBtnStyles, prefixStyles } from './send-otp.module.style';
import { useState } from 'react';
import { Colors } from '@vc-workspace/utils-colors';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
/* eslint-disable-next-line */

export function SendOtp(props) {
  var _useState = useState(''),
      phoneNum = _useState[0],
      setPhoneNum = _useState[1];

  var _useState2 = useState(''),
      errorMessage = _useState2[0],
      setErrorMessage = _useState2[1];

  var _useState3 = useState(false),
      showLoading = _useState3[0],
      toggleLoading = _useState3[1];

  function onInputChange(ev) {
    if (errorMessage) {
      setErrorMessage("");
    }

    setPhoneNum(ev.target.value.trim());
  }

  function onSendOTP() {
    if (!phoneNum) {
      setErrorMessage("Please provide a phone number");
      return;
    }

    if (phoneNum.length !== 10) {
      setErrorMessage("Please enter 10 digit phone number");
      return;
    }

    toggleLoading(true);
    sendAmplitudeData(AmplitudeEventNames.SEND_OTP_CLICKED, {});
    sendOTP(phoneNum).then(function (_ref) {
      var isNewUser = _ref.isNewUser;
      props.onSendOTP(phoneNum, isNewUser);
    })["catch"](function (err) {
      var _err$response, _err$response$data;

      toggleLoading(false);
      setErrorMessage(err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : _err$response$data.message);
    });
  }

  return _jsxs("div", {
    css: containerStyles,
    children: [_jsx("div", {
      className: "px-4",
      children: _jsxs(Typography, {
        type: "Headline_H2",
        color: "nero",
        children: [_jsx("b", {
          children: "Login"
        }), " or ", _jsx("b", {
          children: "Signup"
        })]
      })
    }), _jsxs("div", {
      css: contentContainerStyles,
      className: "px-4",
      children: [_jsxs("div", {
        className: "relative mt-12",
        children: [_jsx("input", {
          type: "number",
          max: 9999999999,
          css: inputStyles,
          value: phoneNum,
          onChange: onInputChange
        }), _jsx(Typography, {
          type: "Body1",
          color: "nero",
          custom_style: prefixStyles,
          semi_bold: true,
          children: "+91"
        })]
      }), errorMessage ? _jsx(Typography, {
        type: "Body2",
        color: "cerise",
        className: "pt-2 pl-4",
        children: errorMessage
      }) : null, showLoading ? _jsx("div", {
        className: "text-center mt-5 text-xs",
        children: _jsx(ScaleLoader, {
          color: Colors.purple_heart,
          height: 24
        })
      }) : _jsx("div", {
        className: "text-center",
        children: _jsx("button", {
          css: sendOTPBtnStyles,
          onClick: onSendOTP,
          children: "Send OTP"
        })
      })]
    })]
  });
}
export default SendOtp;