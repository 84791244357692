import { css } from '@emotion/react';
import { Colors } from '@vc-workspace/utils-colors';
import { DIMENSIONS } from '@vc-workspace/utils-dimens';

export const containerStyles = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between'
});

export const l0_category_styles = css({
  textTransform: 'uppercase',
  paddingLeft: DIMENSIONS.px_8,
  paddingRight: DIMENSIONS.px_8,
  paddingBottom: DIMENSIONS.px_12,
  borderBottom: '3px solid transparent',
  '&:hover': {
    cursor: 'pointer',
    borderColor: Colors.dark_tangerine,
  },
  '&::last-child': {
    marginRight: '0px',
  },
});

export const l0SelectedCategoryStyles = css({
  borderColor: Colors.dark_tangerine,
});

export const dropdownContainerStyles = css({
  position: 'fixed',
  left: '1%',
  top: '125px',
  display: 'inline-block',
  width: '98%',
  background: Colors.white,
  zIndex: 20,
  overflow: 'hidden',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
  textAlign: 'left',
});

export const l1CategoryContainerStyles = css({
  display: 'inline-block',
  overflow: 'auto',
  verticalAlign: 'top',
  flex: 1,
  paddingLeft: DIMENSIONS.px_28,
  paddingRight: DIMENSIONS.px_28,
  paddingBottom: DIMENSIONS.px_28
});
