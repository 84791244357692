import { CategoryFilterKey, DiscountFilterKey, FilterTypes, PriceFilterKey, SortByKey, VibeAccessTokenKey } from '@vc-workspace/utils-constants';
import { getRouteContextData } from '@vc-workspace/utils-helpers';
import axios from 'axios';
import { getCookie } from 'cookies-next';
export function setAccessTokenIfPresent(context) {
  var accessToken = getCookie(VibeAccessTokenKey, {
    req: context.req,
    res: context.res
  });

  if (accessToken) {
    axios.defaults.headers.common['x-access-token'] = accessToken;
  } else {
    delete axios.defaults.headers.common['x-access-token'];
  }

  var routeContext = getRouteContextData(context);

  if (routeContext.isMobileView) {
    axios.defaults.headers.common['x-mp-plt'] = "MOBILE_WEB";
  } else {
    axios.defaults.headers.common['x-mp-plt'] = "DESKTOP_WEB";
  }

  var gaVisitorId = getGAVisitorId(context);

  if (gaVisitorId) {
    axios.defaults.headers.common['x-ga-visitor'] = gaVisitorId;
  } else {
    delete axios.defaults.headers.common['x-ga-visitor'];
  }

  return accessToken;
}
export function getGAVisitorId(context) {
  var gaId = getCookie("_ga", {
    req: context.req,
    res: context.res
  });
  var visitorId = "";

  if (gaId) {
    visitorId = gaId.toString().replace("GA1.1.", "");
  }

  return visitorId;
}
export function getFiltersPayload(context) {
  var filtersPayload = {
    getFilters: true,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  };

  if (context.query[CategoryFilterKey]) {
    var filterValues = [];

    if (typeof context.query[CategoryFilterKey] === 'string') {
      filterValues.push({
        value: context.query[CategoryFilterKey]
      });
    } else {
      filterValues = context.query[CategoryFilterKey].map(function (item) {
        return {
          value: item
        };
      });
    }

    filtersPayload.filters.push({
      filterType: FilterTypes.Category,
      filterValues: filterValues
    });
  }

  if (context.query[PriceFilterKey]) {
    var _filterValues = [];

    if (typeof context.query[PriceFilterKey] === 'string') {
      _filterValues.push({
        value: context.query[PriceFilterKey]
      });
    } else {
      _filterValues = context.query[PriceFilterKey].map(function (item) {
        return {
          value: item
        };
      });
    }

    filtersPayload.filters.push({
      filterType: FilterTypes.Price,
      filterValues: _filterValues
    });
  }

  if (context.query[DiscountFilterKey]) {
    var _filterValues2 = [];

    if (typeof context.query[DiscountFilterKey] === 'string') {
      _filterValues2.push({
        value: context.query[DiscountFilterKey]
      });
    } else {
      _filterValues2 = context.query[DiscountFilterKey].map(function (item) {
        return {
          value: item
        };
      });
    }

    filtersPayload.filters.push({
      filterType: FilterTypes.Discount,
      filterValues: _filterValues2
    });
  }

  if (context.query[SortByKey]) {
    filtersPayload.sortField = context.query[SortByKey];
  }

  if (context.query.sortOrder) {
    filtersPayload.sortOrder = context.query.sortOrder;
  }

  return filtersPayload;
}