import axios from 'axios';
import { setKeyInStorage } from '@vc-workspace/utils-helpers';
import { MARKETPLACE_ACCESS_TOKEN, MARKETPLACE_USER_ID } from '@vc-workspace/utils-constants';

export function sendOTP(phone: string) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/users/otp`,
    params: {
      phone,
      phoneDialCode: '+91',
    },
  }).then((res) => res.data);
}

export function verifyOTP(phone: string, otp: string) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/users/otp/verify`,
    data: {
      phone,
      phoneDialCode: '+91',
      candidateOtp: otp,
    },
  }).then((res: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    axios.defaults.headers.common['x-access-token'] = res.data.token;
    setKeyInStorage(MARKETPLACE_USER_ID, res.data.userId);
    setKeyInStorage(MARKETPLACE_ACCESS_TOKEN, res.data.token);
    return res.data.userId;
  });
}
export function sendPasswordResetLink(email: string) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/users/password/forgot`,
    data: { email },
  }).then((res) => res.data);
}
export function loginUser(data: Record<string, any>) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/users/password/login`,
    data: {
      email: data.email,
      password: data.password,
    },
  }).then((res) => res.data);
}
export function getStartedFormData(token: string) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/customers/formData`,
    headers: {
      'x-access-token': token,
    },
  }).then((res) => res.data);
}
export function postB2BCustomerDetails(data: Record<string, any>, token: string) {
  return axios({
    method: 'post',
    headers: {
      'x-access-token': token,
    },
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/customers`,
    data,
  }).then((res) => res.data);
}

export function signUpUser(data: Record<string, string>) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/users/password/signup`,
    data,
  }).then((res) => res.data);
}
