
import axios from 'axios';

export function getProducts(reqPayload: any): Promise<any> {

  return axios({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/products`,
    data: reqPayload
  }).then(res => res.data).catch(console.log)
}

export function getGlobalProducts(reqPayload: any) {

  return axios({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/mall/products`,
    data: reqPayload
  }).then(res => res.data)
}