/** @jsxImportSource @emotion/react */

import { separatorStyles } from "./style";

/* eslint-disable-next-line */
export interface UtilsSeparatorProps {
  color: string,
  spacingTop?: string,
  spacingBottom?: string,
  seperatorHeight?: string,
  borderRadius?: string
}

export function UtilsSeparator(props: UtilsSeparatorProps = {
  color: 'gainsboro',
  spacingTop: '0px',
  spacingBottom: '0px',
  seperatorHeight: '1px',
  borderRadius: '0px'
}) {
  return (
    <div css={separatorStyles(props.color, props.spacingTop, props.spacingBottom, props.seperatorHeight, props.borderRadius)} />
  );
}
