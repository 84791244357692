
import { getGAClientId } from '@vc-workspace/utils-ga';
import { BannerGroupSchema, CategoryCollectionGroupSchema, CollectionGroupSchema, HeroBannerSchema, ItemCollectionSchema, ShopGroupSchema } from '@vc-workspace/utils-schemas';
import axios from 'axios';

export type LandingPageResponse = {
  sessionId: string,
  totalWidgetCount: number,
  widgets: (ItemCollectionSchema | CollectionGroupSchema | ShopGroupSchema | CategoryCollectionGroupSchema | BannerGroupSchema | HeroBannerSchema)[]
}

export async function getLandingPageSections(sessionId?: string, pageSize?: number, pageNumber?: number, platform?: string, usId?: string): Promise<LandingPageResponse> {
  
  const res = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/landingPages/widgets`,
    params: {
      sessionId,
      limit: pageSize,
      offset: pageNumber,
      platform,
      version: "V2",
      usId,
      isDraftSession: usId ? true : undefined
    }
  });
  return res.data;
}


export function getGlobalLandingPageSections(sessionId?: string, pageSize?: number, pageNumber?: number, platform?: string): any {
  
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/landingPages/widgets`,
    params: {
      sessionId,
      limit: pageSize,
      offset: pageNumber,
      platform,
      version: "V2"
    }
  }).then(res => res.data)
}