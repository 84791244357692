import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { iconStyles, addedNotificationContainer, actionNotificationProductImage } from './navbar-wishlist.module.style';
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { Typography } from '@vc-workspace/utils-typography';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
import { TailwindColors } from '@vc-workspace/utils-colors';
import { useRouter } from 'next/router';
/* eslint-disable-next-line */

export function NavbarWishlist(props) {
  var router = useRouter();

  var _useContext = useContext(AppConfigContext),
      itemAddedToWishlist = _useContext.itemAddedToWishlist,
      setItemAddedToWishlist = _useContext.setItemAddedToWishlist,
      isLoggedIn = _useContext.isLoggedIn,
      toggleLoginModalVisibility = _useContext.toggleLoginModalVisibility;

  function onWishlistClick() {
    sendAmplitudeData(AmplitudeEventNames.GO_TO_WISHLIST, {
      source: 'Navbar'
    });
    router.push('/profile/wishlist');
  }

  function onNotificationClick(ev) {
    ev.stopPropagation();
    setItemAddedToWishlist(null);

    if (!isLoggedIn) {
      toggleLoginModalVisibility(true);
    } else {
      router.push('/profile/wishlist');
    }
  }

  var productImage = {
    url: '',
    webP: ''
  };

  if (itemAddedToWishlist) {
    productImage = getDisplayImageObj(itemAddedToWishlist.itemDetail.images[0], "1");
  }

  return _jsxs("div", {
    onClick: onWishlistClick,
    className: "inline-block relative cursor-pointer mr-4",
    children: [props.whiteTheme ? _jsx("img", {
      src: "/_mp-images/heart_white.svg",
      css: iconStyles
    }) : _jsx("img", {
      src: "/_mp-images/heart_black.svg",
      css: iconStyles
    }), itemAddedToWishlist && !props.context.isMobileView ? _jsxs("div", {
      css: addedNotificationContainer,
      onClick: onNotificationClick,
      children: [_jsxs("picture", {
        children: [_jsx("source", {
          srcSet: productImage.webP,
          type: "image/webp"
        }), _jsx("img", {
          src: productImage.url,
          css: actionNotificationProductImage
        })]
      }), _jsxs("div", {
        className: "pl-2 text-left",
        children: [_jsx(Typography, {
          type: "SubHeading1",
          color: TailwindColors.grey[900],
          semi_bold: true,
          children: "Added to wishlist"
        }), !isLoggedIn ? _jsx(Typography, {
          type: "Body1",
          color: TailwindColors.purple[800],
          semi_bold: true,
          children: "Login to save"
        }) : null]
      })]
    }) : null]
  });
}
export default NavbarWishlist;