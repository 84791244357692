import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { useRouter } from 'next/router';
import { redDotStyles, iconStyles, addedNotificationContainer, actionNotificationProductImage } from './navbar-checkout.module.style';
import { useContext, useEffect, useState } from 'react';
import { getCartFromLocal } from '../utilities/utilities';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { Typography } from '@vc-workspace/utils-typography';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
import { TailwindColors } from '@vc-workspace/utils-colors';
/* eslint-disable-next-line */

export function NavbarCheckout(props) {
  var router = useRouter();

  var _useContext = useContext(AppConfigContext),
      itemAddedToCart = _useContext.itemAddedToCart,
      setItemAddedToCart = _useContext.setItemAddedToCart;

  var _useState = useState(false),
      showRedDot = _useState[0],
      toggleRedDot = _useState[1];

  function onCartClick() {
    sendAmplitudeData(AmplitudeEventNames.GO_TO_CART, {
      source: 'Navbar'
    });
    router.push('/cart/init');
  }

  function onNotificationClick(ev) {
    ev.stopPropagation();
    setItemAddedToCart(null);
    router.push('/cart/init');
  }

  useEffect(function () {
    if (getCartFromLocal().items.length) {
      toggleRedDot(true);
    }
  }, []);
  var productImage = {
    url: '',
    webP: ''
  };

  if (itemAddedToCart) {
    productImage = getDisplayImageObj(itemAddedToCart.itemDetail.images[0], "1");
  }

  return _jsxs("div", {
    onClick: onCartClick,
    className: "inline-block relative cursor-pointer",
    children: [props.whiteTheme ? _jsx("img", {
      src: "/_mp-images/bag_white.svg",
      css: iconStyles
    }) : _jsx("img", {
      src: "/_mp-images/bag-outline.svg",
      css: iconStyles
    }), showRedDot || itemAddedToCart ? _jsx("div", {
      id: "red_dot",
      css: redDotStyles
    }) : null, itemAddedToCart && !props.context.isMobileView ? _jsxs("div", {
      css: addedNotificationContainer,
      onClick: onNotificationClick,
      children: [_jsxs("picture", {
        children: [_jsx("source", {
          srcSet: productImage.webP,
          type: "image/webp"
        }), _jsx("img", {
          src: productImage.url,
          css: actionNotificationProductImage
        })]
      }), _jsx(Typography, {
        type: "Body1",
        color: TailwindColors.grey[900],
        className: "text-center",
        semi_bold: true,
        children: "Added to bag"
      })]
    }) : null]
  });
}
export default NavbarCheckout;