import { USER_VISIT_METADATA_KEY } from '@vc-workspace/utils-constants';
import { getGAClientId } from '@vc-workspace/utils-ga';
import { getPaymentCallbackUrl, LocalStorage, sanitiseArray } from '@vc-workspace/utils-helpers';
import { PaymentEntriesType, Shop } from '@vc-workspace/utils-schemas';
import axios from 'axios';

export type CartAddItem = {
  sia: string;
  ipq: string;
  quantity: number;
  price: number;
  currency: string;
};

export function addItemsToCart(items: CartAddItem[], shippingAddress?: string, billingAddress?: string, shop?: Shop) {
  return axios({
    method: 'PUT',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me`,
    data: {
      orderType: shop ? 'SHOP' : 'MARKETPLACE',
      shopId: shop ? shop._id : undefined,
      shippingAddress: shippingAddress || undefined,
      billingAddress: billingAddress || undefined,
      items,
      metadata: {
        att_src: sanitiseArray(LocalStorage.getKey(USER_VISIT_METADATA_KEY)) || [],
      },
    },
  }).then((res: any) => {
    return {
      ...res.data.cart,
      isUPIEnabled: res.data.availablePaymentMethods.indexOf('upi') !== -1,
      isRazorPayEnabled: res.data.availablePaymentMethods.indexOf('others') !== -1,
    };
  });
}

export function getMyCart(shop?: Shop) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me`,
    params: {
      orderType: shop ? 'SHOP' : 'MARKETPLACE',
      shopId: shop ? shop._id : undefined,
    },
  }).then((res: any) => {
    return {
      ...res.data.cart,
      isUPIEnabled: res.data.availablePaymentMethods.indexOf('upi') !== -1,
      isRazorPayEnabled: res.data.availablePaymentMethods.indexOf('others') !== -1,
    };
  });
}

export async function checkoutFromCart(amount: number, currencyId: string, paymentEntries: PaymentEntriesType[], shop?: Shop){
  const gaClientId = await getGAClientId();
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/checkout`,
    data: {
      orderType: shop ? 'SHOP' : 'MARKETPLACE',
      shopId: shop ? shop._id : undefined,
      amount,
      currencyId,
      callbackUrl: getPaymentCallbackUrl(amount, '', shop),
      paymentEntries,
      clientId: gaClientId
    }
  }).then(res => res.data);
}

export function retryPayment(orderUsId: string, paymentMethod: string, amount: number, shop?: Shop) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/orders/retryPayment`,
    data: {
      orderUsId,
      paymentMethod,
      callbackUrl: getPaymentCallbackUrl(amount, orderUsId, shop),
    },
  }).then((res) => res.data);
}

export function checkCODAvailability(orderType: string) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/codServiceability`,
    params: {
      orderType,
    },
  }).then((res: any) => res.data.isCodServiceable);
}

export function getCartOffers(): any {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/availableOffers`,
    params: {
      orderType: 'MARKETPLACE',
    },
  }).then((res: any) => res.data.offers);
}

export function checkOfferStatus(offerCode: string) {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/${offerCode.trim()}/checkOffer`,
  }).then((res) => res.data);
}

export function applyOfferToCart(appliedOffers: string[]) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/applyMultipleOffers `,
    data: {
      offerCodes: appliedOffers,
    },
  }).then((res) => res.data);
}

export function removeCartOffer() {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/carts/me/removeOffer`,
  }).then((res) => res.data);
}
