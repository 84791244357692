import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { Typography } from '@vc-workspace/utils-typography';
import ScaleLoader from "react-spinners/ScaleLoader";
import { sendOTP, verifyOTP } from '@vc-workspace/data-adapter';
import { containerStyles, contentContainerStyles, inputStyles, sendOTPBtnStyles } from './verify-otp.module.style';
import { useEffect, useState } from 'react';
import { Colors } from '@vc-workspace/utils-colors';
import { getDoubleDigit } from '@vc-workspace/utils-helpers';
import { UtilsCustomSnackbar } from '@vc-workspace/utils-snackbar';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
/* eslint-disable-next-line */

var OTP_START_TIMER = 40;
export function VerifyOtp(props) {
  var _useState = useState(''),
      otp = _useState[0],
      setOTP = _useState[1];

  var _useState2 = useState(''),
      errorMessage = _useState2[0],
      setErrorMessage = _useState2[1];

  var _useState3 = useState(false),
      showLoading = _useState3[0],
      toggleLoading = _useState3[1];

  var _useState4 = useState(OTP_START_TIMER),
      otpTimer = _useState4[0],
      setOTPTimer = _useState4[1];

  var _useState5 = useState(false),
      otpResendSuccess = _useState5[0],
      setOTPResendSuccess = _useState5[1];

  useEffect(function () {
    if (otpTimer > 0) {
      setTimeout(function () {
        setOTPTimer(otpTimer - 1);
      }, 1000);
    }
  }, [otpTimer]);

  function onInputChange(ev) {
    if (errorMessage) {
      setErrorMessage("");
    }

    setOTP(ev.target.value.trim());
  }

  function onVerifyOTP() {
    if (!otp) {
      setErrorMessage("Please provide a otp");
      return;
    }

    if (otp.length !== 6) {
      setErrorMessage("Please enter 6 digit otp");
      return;
    }

    toggleLoading(true);
    verifyOTP(props.phone, otp).then(function (userId) {
      if (window && window.branch) {
        window.branch.setIdentity(userId);
      }

      props.onVerifyOTP();
    })["catch"](function (err) {
      var _err$response, _err$response$data;

      toggleLoading(false);
      setErrorMessage(err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : _err$response$data.message);
      setTimeout(function () {
        return setErrorMessage("");
      }, 5000);
    });
  }

  function onResendOTP() {
    sendAmplitudeData(AmplitudeEventNames.RESEND_OTP_CLICKED, {});
    sendOTP(props.phone).then(function () {
      setOTPResendSuccess(true);
      setOTPTimer(OTP_START_TIMER);
    })["catch"](function (err) {
      var _err$response2, _err$response2$data;

      setErrorMessage(err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : (_err$response2$data = _err$response2.data) === null || _err$response2$data === void 0 ? void 0 : _err$response2$data.message);
      setTimeout(function () {
        return setErrorMessage("");
      }, 5000);
    });
  }

  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      css: containerStyles,
      children: [_jsxs("div", {
        className: "px-4",
        children: [_jsx(Typography, {
          type: "Headline_H2",
          color: "nero",
          bold: true,
          children: "Verify OTP"
        }), _jsx(Typography, {
          type: "Body1",
          color: "nero",
          className: "pt-5",
          bold: true,
          children: "Enter 6 digit OTP sent to"
        }), _jsxs(Typography, {
          type: "SubHeading1",
          color: "dim_grey",
          bold: true,
          children: ["+91-", props.phone]
        })]
      }), _jsxs("div", {
        css: contentContainerStyles,
        className: "px-4",
        children: [_jsx("div", {
          className: "relative",
          children: _jsx("input", {
            type: "text",
            placeholder: "XXXXXX",
            maxLength: 6,
            css: inputStyles,
            value: otp,
            onChange: onInputChange
          })
        }), errorMessage ? _jsx(Typography, {
          type: "Body2",
          color: "cerise",
          className: "pt-2 pl-4",
          children: errorMessage
        }) : null, _jsx("div", {
          className: "text-right pt-2",
          children: otpTimer > 0 ? _jsxs(_Fragment, {
            children: [_jsx(Typography, {
              type: "Body2",
              color: "night_rider",
              inline: true,
              children: "Resend OTP in:\xA0"
            }), _jsxs(Typography, {
              type: "Body2",
              color: "purple_heart",
              inline: true,
              bold: true,
              children: ["00:", getDoubleDigit(otpTimer)]
            })]
          }) : _jsxs(_Fragment, {
            children: [_jsx(Typography, {
              type: "Body2",
              color: "night_rider",
              inline: true,
              children: "Didn't receive the OTP?\xA0"
            }), _jsx(Typography, {
              type: "Body2",
              color: "purple_heart",
              onClick: onResendOTP,
              inline: true,
              bold: true,
              children: "Resend OTP"
            })]
          })
        }), showLoading ? _jsx("div", {
          className: "text-center mt-5",
          children: _jsx(ScaleLoader, {
            color: Colors.purple_heart,
            height: 24
          })
        }) : _jsx("button", {
          css: sendOTPBtnStyles,
          onClick: onVerifyOTP,
          children: "Verify & Login"
        })]
      })]
    }), _jsx(UtilsCustomSnackbar, {
      open: otpResendSuccess,
      bgColor: Colors.off_yellow,
      textColor: Colors.night_rider,
      autoHideDuration: 5000,
      onClose: function onClose() {
        return setOTPResendSuccess(false);
      },
      vertical: 'bottom',
      horizontal: 'center',
      message: "OTP sent successfully"
    })]
  });
}
export default VerifyOtp;