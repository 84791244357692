
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

/* eslint-disable-next-line */
export interface UtilsSnackbarProps {
  open: boolean,
  autoHideDuration: number,
  onClose: () => void,
  vertical: "bottom" | "top",
  horizontal: "left" | "right" | "center",
  message: string,
  bgColor?: string,
  textColor?: string,
  customSx?: any
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function UtilsErrorSnackbar(props: UtilsSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      sx={props.customSx}
      onClose={props.onClose}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}>
      <Alert onClose={props.onClose} severity="error" sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export function UtilsSuccessSnackbar(props: UtilsSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      sx={props.customSx}
      onClose={props.onClose}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}>
      <Alert onClose={props.onClose} severity="success" sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export function UtilsWarningSnackbar(props: UtilsSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      sx={props.customSx}
      onClose={props.onClose}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}>
      <Alert onClose={props.onClose} severity="warning" sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export function UtilsInfoSnackbar(props: UtilsSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      sx={props.customSx}
      onClose={props.onClose}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}>
      <Alert onClose={props.onClose} severity="info" sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export function UtilsCustomSnackbar(props: UtilsSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
      sx={props.customSx}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}>
      <Alert onClose={props.onClose} severity="info" sx={{ width: '100%', background: props.bgColor, color: props.textColor }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
