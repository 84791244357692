import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import axios from 'axios';
import { Typography } from '@vc-workspace/utils-typography';
import Login from '../login/login';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { useContext, useRef, useState } from 'react';
import { removeKeyFromStorage } from '@vc-workspace/utils-helpers';
import { MARKETPLACE_ACCESS_TOKEN, VC_APP_GUEST_REFER_AND_EARN_KEY, VC_APP_REFER_AND_EARN_KEY } from '@vc-workspace/utils-constants';
import { iconStyles } from './navbar-profile.module.style';
import MyAccountMenuItem from './my-account-menu-item';
import { ScanQRDownload } from '../app-banners/download-app';
import MyAccountHeader from './my-account-header';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import GuestAccountHeader from './guest-account-header';
import { TailwindColors } from '@vc-workspace/utils-colors';
/* eslint-disable-next-line */

export function NavbarProfile(props) {
  var anchorEle = useRef(null);

  var _useState = useState(false),
      modalOpen = _useState[0],
      toggleModal = _useState[1];

  var _useState2 = useState(false),
      isLoggedIn = _useState2[0],
      toggleLoggedIn = _useState2[1];

  var _useContext = useContext(AppConfigContext),
      user = _useContext.user,
      setLoggedInState = _useContext.setLoggedInState;

  function onLoginSuccess() {
    toggleLoggedIn(true);
  }

  var openSupport = function openSupport() {
    var supportUrl = 'https://vibecity.typeform.com/ConsumAddTicket';

    if (user) {
      supportUrl += "#phone=".concat(user.phoneDialCode, "-").concat(user.phone);
    }

    toggleModal(false);
    window.open(supportUrl, '_blank');
  };

  function onAlreadyLoggedIn() {
    toggleLoggedIn(true);
  }

  function onLogout() {
    toggleModal(false);
    toggleLoggedIn(false);
    setLoggedInState(false);
    removeKeyFromStorage(MARKETPLACE_ACCESS_TOKEN);
    removeKeyFromStorage(VC_APP_REFER_AND_EARN_KEY);
    removeKeyFromStorage(VC_APP_GUEST_REFER_AND_EARN_KEY);
    delete axios.defaults.headers.common[MARKETPLACE_ACCESS_TOKEN];

    if (window && window.branch) {
      window.branch.logout();
    }
  }

  function onMyOrdersClick() {
    toggleModal(false);
    window.location.href = '/profile/orders';
  }

  function onMyWishlistClick() {
    toggleModal(false);
    window.location.href = '/profile/wishlist';
  }

  function onMyWalletClick() {
    toggleModal(false);
    window.location.href = '/profile/wallet';
  }

  function onReferAndEarnClick() {
    toggleModal(false);
    window.location.href = '/profile/referral?source=account';
  }

  function onSuccessLogin() {
    toggleModal(false);
    window.location.reload();
  }

  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      ref: anchorEle,
      className: "inline-block cursor-pointer ",
      onClick: function onClick() {
        return toggleModal(true);
      },
      children: props.whiteTheme ? _jsx("img", {
        src: "/_mp-images/profile_white.svg",
        css: iconStyles
      }) : _jsx("img", {
        src: "/_mp-images/profile.svg",
        css: iconStyles
      })
    }), _jsxs(Menu, {
      id: "profile-menu",
      anchorEl: anchorEle.current,
      open: modalOpen,
      onClose: function onClose() {
        return toggleModal(false);
      },
      className: "mt-2",
      MenuListProps: {
        'aria-labelledby': 'basic-button'
      },
      disableAutoFocusItem: true,
      children: [isLoggedIn ? _jsx(MyAccountHeader, {}) : _jsx(Login, {
        triggerEle: _jsx("div", {
          children: _jsx(GuestAccountHeader, {})
        }),
        onAlreadyLoggedIn: onAlreadyLoggedIn,
        onSuccessfullLogin: onSuccessLogin,
        context: props.context
      }), _jsx(MenuItem, {
        children: _jsx(Login, {
          triggerEle: _jsx("div", {
            className: "w-full",
            children: _jsx(MyAccountMenuItem, {
              title: 'Wallet',
              icon: '/_mp-images/my_account_wallet.png',
              showWalletBalance: true
            })
          }),
          onAlreadyLoggedIn: onAlreadyLoggedIn,
          onSuccessfullLogin: onMyWalletClick,
          context: props.context
        })
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), _jsx(MenuItem, {
        children: _jsx(Login, {
          triggerEle: _jsx("div", {
            className: "w-full",
            children: _jsx(MyAccountMenuItem, {
              title: 'Orders',
              icon: '/_mp-images/my_account_orders.png'
            })
          }),
          onAlreadyLoggedIn: onAlreadyLoggedIn,
          onSuccessfullLogin: onMyOrdersClick,
          context: props.context
        })
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), _jsx(MenuItem, {
        children: _jsx(Login, {
          triggerEle: _jsx("div", {
            className: "w-full",
            children: _jsx(MyAccountMenuItem, {
              title: 'Wishlist',
              icon: '/_mp-images/my_account_wishlist.png'
            })
          }),
          onAlreadyLoggedIn: onAlreadyLoggedIn,
          onSuccessfullLogin: onMyWishlistClick,
          context: props.context
        })
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), _jsx(MenuItem, {
        children: _jsx(Login, {
          triggerEle: _jsx("div", {
            className: "w-full",
            children: _jsx(MyAccountMenuItem, {
              title: 'Help & Support',
              icon: '/_mp-images/my_account_support.png'
            })
          }),
          onAlreadyLoggedIn: onAlreadyLoggedIn,
          onSuccessfullLogin: openSupport,
          context: props.context
        })
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), _jsx("div", {
        className: "w-full px-4 py-1",
        children: _jsx(ScanQRDownload, {})
      }), _jsx(UtilsSeparator, {
        color: TailwindColors.grey[100]
      }), isLoggedIn ? _jsx(_Fragment, {
        children: _jsx(MenuItem, {
          children: _jsx(Typography, {
            type: "Body1",
            color: TailwindColors.grey[600],
            className: "py-2",
            onClick: onLogout,
            children: "Logout"
          })
        })
      }) : null]
    })]
  });
}
export default NavbarProfile;