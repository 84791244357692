
declare global {
  interface Window { gtag: any, _gre: any[], cloud_retail: any }
}

export const getGAClientId = (): Promise<string> => {
  return new Promise(resolve => {
    if(!window || !window.gtag) {
      return resolve("");
    }
    window.gtag('get', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY, 'client_id', resolve);
  });
}

export function trackGAEvent(
  eventName: string,
  eventProperties: GAEventProperties
) {

  if(typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, eventProperties)
  }
}

export const GAEventCategory = {
  'Checkout': 'Checkout',
  'Enquiry': 'Enquiry',
  'Engagement': 'Engagement'
};

export const GAEvents = {
  'ADD_TO_WISHLIST': 'add_to_wishlist',
  'ADD_TO_CART': 'add_to_cart',
  'SELECT_ADDRESS': 'Select Address',
  'PROCEED_TO_PAY': 'Proceed to Pay',
  'CONFIRM_TO_PAY': 'Confirm to Pay',
  'VIEW_ITEM': 'view_item',
  'PRODUCT_GALLERY_OPEN': 'Product Gallery Open',
  'VIEW_OFFERS': 'View Offers',
  'SELECT_VARIATION': 'Select Variation',
  'SIGNUP': 'Signup',
  'ORDER_SAMPLE_ADDED': 'Order Sample Added',
  'PRODUCT_CARD_VIEWED': 'product_card_viewed'
}

type GAItemPropertySchema = {
  item_id?: string,
  item_name?: string,
  item_brand?: string,
  item_category?: string,
  item_category2?: string,
  item_category3?: string,
  price?: number
}

export type GAEventProperties = {
  eventCategory?: string,
  eventAction?: string,
  eventValue?: number,
  eventLabel?: string,
  value?: number,
  items?: GAItemPropertySchema[],
  currency?: string,
  source?: string,
  sourceName?: string,
  /**** Below properties to be sent only for custom events */
  item_id?: string,
  item_name?: string,
  item_brand?: string,
  item_category?: string,
  item_category2?: string,
  item_category3?: string,
  price?: number,
  cardPosition?: number,
  cardRowPosition?: number
  /***************************************/
}

export enum RetailEventTypes {
  ADD_TO_CART = 'add-to-cart',
  CATEGORY_PAGE_VIEW = 'category-page-view',
  DETAIL_PAGE_VIEW = 'detail-page-view',
  HOME_PAGE_VIEW = 'home-page-view',
  SEARCH = 'search',
  SHOPPING_CART_PAGE_VIEW = 'shopping-cart-page-view'
}

export type RetailEventProperties = {
  eventType: RetailEventTypes,
  visitorId?: string,
  userInfo?: {
    userId: string
  },
  productDetails?: {
    product: {
      id: string
    },
    quantity?: number
  }[],
  pageViewId?: string,
  completionDetail?: {
    completionAttributionToken: string,
    selectedSuggestion: string,
    selectedPosition: number
  },
  searchQuery?: string,
  pageCategories?: string[]
}

export async function trackGARetailEvent(eventDetails: RetailEventProperties) {

  if(window._gre && process.env.NEXT_PUBLIC_RETAIL_API_KEY && process.env.NEXT_PUBLIC_RETAIL_PROJECT_ID) {
    eventDetails.visitorId = await getGAClientId();
    window._gre.push(['apiKey', `${process.env.NEXT_PUBLIC_RETAIL_API_KEY}`]);
    window._gre.push(['projectId', `${process.env.NEXT_PUBLIC_RETAIL_PROJECT_ID}`]);
    window._gre.push(['locationId', 'global']);
    window._gre.push(['catalogId', 'default_catalog']);
    window._gre.push(['logEvent', eventDetails]);

    try {
      window.cloud_retail.logEvent(window._gre);
    } catch (err) {
      console.log(err);
    }
  }
}