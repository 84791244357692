
import { getGAClientId } from '@vc-workspace/utils-ga';
import axios from 'axios';

export function getSearchResults(reqPayload: any) {

  if(!reqPayload.start) {
    reqPayload.start = 0;
  }

  return axios({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/products/namedSearch`,
    data: reqPayload
  }).then(res => res.data)
}

export async function getGlobalSearchResults(reqPayload: any) {

  if(!reqPayload.start) {
    reqPayload.start = 0;
  }

  const visitorId = await getGAClientId();

  if(visitorId) {
    reqPayload.visitorId = visitorId;
  }

  const res = await axios({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/mall/products/namedSearch`,
    data: reqPayload
  });
  return res.data;
}