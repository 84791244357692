
import {
  Address,
  Category,
  CategoryDetail,
  CategoryMappings,
  DynamicKeyNumSchema,
  DynamicKeySchema,
  DynamicValueSchema,
  ProductCategoryDetails,
} from '@vc-workspace/utils-schemas';
import queryString from 'query-string';

const MONTHS = ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export function getMappedCategories(allCategories: Category[], categoryId: string, categoryLevel: number): CategoryMappings[] {
  if (!allCategories || !allCategories.length) {
    return [];
  }

  let categoryMappings: CategoryMappings[] = [];

  // Add l1 categories
  if(categoryLevel === 1) {
    categoryMappings = allCategories
      .filter((item) => item._id === categoryId)
      .map((item) => ({
        category: item,
        childrens: [],
      }));
  } else {
    categoryMappings = allCategories
      .filter((item) => item.parent === categoryId && item.level === 1)
      .map((item) => ({
        category: item,
        childrens: [],
      }));
  }
  

  // Add corresponding l2 categories
  categoryMappings = categoryMappings.map((l1Category) => {
    l1Category.childrens = allCategories.filter((item) => item.parent === l1Category.category._id);

    return l1Category;
  });

  return categoryMappings;
}

export function amountFormatter(amount?: number): string {
  if (typeof amount === 'undefined' || Number.isNaN(amount)) {
    return '--';
  }

  let formatted_amount = '';

  if (Math.round(amount) !== amount) {
    formatted_amount = amount.toFixed(2);
  } else {
    formatted_amount = amount.toString();
  }

  return formatted_amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '--';
}

export function getAddressString(address: Address): string {
  if (!address) {
    return '';
  }

  let landmark = '';

  if (address.landmark) {
    landmark = `Near: ${address.landmark}`;
  }

  return `${address.apartment} ${address.street}, ${landmark},  ${address.city} ${address.state}, ${address.country} - ${address.pinCode}`;
}

export function getParentCategories(allCategories: Category[], categoryId: string): CategoryDetail {
  const categoryDetails: CategoryDetail = {
    category: allCategories.filter((item) => item?._id === categoryId)[0],
  };

  let parentCategory: Category = allCategories.filter((item) => item?._id === categoryDetails.category?._id)[0];

  if (parentCategory?.level === 0) {
    categoryDetails.l0Category = parentCategory;
  } else {
    categoryDetails.l1Category = parentCategory;

    parentCategory = allCategories.filter((item) => item?._id === parentCategory?._id)[0];
    categoryDetails.l0Category = parentCategory;
  }

  return categoryDetails;
}

export function getProductCategoryDetails(allCategories: Category[], productCategoryIds: string[]): ProductCategoryDetails {
  const categories: Category[] = [];

  productCategoryIds.forEach((productCategory) => {
    const category = allCategories.filter((item) => item._id === productCategory)[0];
    categories.push(category);
  });

  return {
    l0Categories: categories.filter((item) => item?.level === 0),
    l1Categories: categories.filter((item) => item?.level === 1),
    l2Categories: categories.filter((item) => item?.level === 2),
  };
}

export function getFormattedDate(iso_date?: string, exclude_year?: boolean): string {
  if (!iso_date) {
    return '';
  }

  const date = new Date(iso_date);

  const month_date = date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

  const formatted_date = `${month_date} ${MONTHS[date.getMonth()]}${!exclude_year ? `, ${date.getFullYear()}` : ''}`;

  return formatted_date;
}

export function convertJsonToQueryString(queryJson: DynamicValueSchema): string {
  return queryString.stringify(queryJson);
}

export const convertTagToText = (tag: string, preserveCase?: boolean): string => {
  if (!tag) return '';
  const str = tag.replace(/\_/g, ' '); // eslint-disable-line no-useless-escape
  if (!str) return '';
  if (!preserveCase) {
    return convertToTitleCase(str);
  }
  return str;
};

export function convertToTitleCase(str: string): string {
  if (!str) return '';

  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function convertSecondsToMin(time: number): string {
  const m = Math.floor(time % 3600 / 60).toString().padStart(2,'0'),
          s = Math.floor(time % 60).toString().padStart(2,'0');
    
  return m + ':' + s;
}

export function getFormattedNumber(input: number): string {

  if(!input && input !== 0) {
    return '';
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function(item) {
    return input >= item.value;
  });
  return item ? (input / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}
 