
import { css } from "@emotion/react";
import {Colors} from '@vc-workspace/utils-colors';
import {DIMENSIONS} from '@vc-workspace/utils-dimens';

export const separatorStyles = (color: string, spacingTop?: string, spacingBottom?: string, seperatorHeight?: string, borderRadius?: string) => css({
  width: '100%',
  height: seperatorHeight || '1px',
  background: Colors[color] || color,
  marginTop: spacingTop || '0px',
  marginBottom: spacingBottom || '0px',
  borderRadius: borderRadius || '0px'
})