export var PageCacheKeys = {
  CATEGORY_PAGE: 'getCategoryProducts',
  SHOP_CATALOGUE_PAGE: 'getShopCatalogueProducts',
  SHOP_OFFER_PAGE: 'getShopOfferProducts',
  SEARCH_RESULTS: 'getSearchProducts',
  SHOP_PAGE: 'getShopProducts',
  PRODUCT_PAGE: 'getProductPage',
  PRODUCT_REVIEWS: 'getProductReviews',
  SHOP_REVIEWS: 'getShopReviews'
}; // Mapping Contract: [shopId]: pixel key

export var ShopPixelKeys = {
  // Turyam: https://www.vibecity.com/turiyam
  '60f521d350d600001105d20b': '584812481600230',
  // Purple: https://www.vibecity.com/purple
  '618a1c1e5685d2001139d5d6': '10201917314408992',
  // Ekani: https://www.vibecity.com/ekanibymonashstudio
  '6218980bb81d80001047f44d': '300693852',
  // Lather Up: https://www.vibecity.com/latherup-naturals
  '60fc464dd54ed800104aa7ea': '197454790464035',
  // Sattwa Studio: https://www.vibecity.com/sattwa-studio
  '60deb861b0a1ff424d9afcc1': '332066737412441',
  // Jophiel: https://www.vibecity.com/jophiel
  '616536c8f45f6b0010275b2b': '648174020167606',
  // Stunzilla: https://www.vibecity.com/stunzilla
  '6153f3a290107900118e6ec2': '352234063447387'
};