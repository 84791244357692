
import { RetailRecommendationTypes } from '@vc-workspace/utils-constants';
import { getGAClientId } from '@vc-workspace/utils-ga';
import { IPQ, PublicDisplayReviewSchema, Shop, SIA, SIATagSchema } from '@vc-workspace/utils-schemas';
import axios from 'axios';

export type ProductDetailsResponse = {
  sia: SIA;
  prices: IPQ[];
};

export type EnquiryPayloadSchema = {
  phoneDialCode: string;
  phone: string;
  siaId: string;
  quantity: number;
  name: string;
  email: string;
  disableCommunications?: boolean;
};

interface GetProductDetailsMetaParams {
  includeDeleted?: boolean,
  getSampleReviews?: boolean,
  getRatings?: boolean,
  excludeIpqs?: boolean,
  excludeShop?: boolean
}

export type ProductReviewDataSchema = {
  averageRating?: number,
  totalRatings?: number,
  totalProductReviewsCount?: number,
  totalShopReviewsCount?: number,
  sampleProductReviews?: PublicDisplayReviewSchema[],
  sampleShopReviews?: PublicDisplayReviewSchema[]
}

export type GetProductDetailsResponse = {
  sia: SIA,
  shop: Shop,
  ipqs: IPQ[],
  siaTags: SIATagSchema,
  reviewData?: ProductReviewDataSchema
}

export function getProductDetails(usId: string, metaParams?: GetProductDetailsMetaParams): Promise<GetProductDetailsResponse> {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/mall/products/${usId}`,
    params: metaParams
  }).then((res: any) => res.data);
}

export function getGlobalProductDetails(usId: string, shopSlug?: string): any {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/mall/products/${usId}`,
    params: {
      needsPrice: true
    },
  }).then((res: any) => {
    return {
      sia: res.data.sia,
      shop: res.data.shop,
      prices: res.data.ipqs,
    };
  });
}

export type ProductRecommendationResponse = {
  sias: SIA[];
  siasCount: number;
};

export async function getProductRecommendations(usId: string, type: RetailRecommendationTypes, currPage?: number, version?: string): Promise<ProductRecommendationResponse> {
  const page = currPage || 1;

  const limit = type === RetailRecommendationTypes.PRODUCT_PAGE_SHOP_SIMILAR_ITEMS ? 18 : 12;

  const visitorId = await getGAClientId();

  const res = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/recommendation`,
    params: {
      itemUsId: usId,
      type,
      start: limit * (page - 1),
      limit,
      v: version,
      visitorId: visitorId || undefined
    },
  });

  return res.data;
}

export async function getGlobalProductRecommendations(usId: string, type: RetailRecommendationTypes, currPage?: number, version?: string): Promise<ProductRecommendationResponse> {
  const page = currPage || 1;

  const limit = 12;

  const visitorId = await getGAClientId();

  const res = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/recommendations`,
    params: {
      itemUsId: usId,
      type,
      start: limit * (page - 1),
      limit,
      v: version,
      visitorId: visitorId || undefined
    },
  });
  return res.data;
}

export function getProductOffers(usId: string): any {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/mall/vibeCityOffers`,
    params: {
      itemUsId: usId,
    },
  }).then((res: any) => res.data);
}

export function getGlobalProductOffers(usId: string): any {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/vibeCityOffers`,
    params: {
      itemUsId: usId,
    },
  }).then((res: any) => res.data.offers);
}

export function createProductEnquiryRequest(payload: EnquiryPayloadSchema): any {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/support/enquiries`,
    data: payload,
  }).then((res) => res.data);
}
