import { BannerGroupSchema, CategoryCollectionGroupSchema, CollectionGroupSchema, HeroBannerSchema, ItemCollectionSchema, ShopGroupSchema } from "@vc-workspace/utils-schemas";
import axios from "axios";

export type CurationPageResponse = {
  sessionId: string,
  totalWidgetCount: number,
  pageTitle: string,
  widgets: (ItemCollectionSchema | CollectionGroupSchema | ShopGroupSchema | CategoryCollectionGroupSchema | BannerGroupSchema | HeroBannerSchema)[]
}

export function getCurationPageSections(usId: string, sessionId?: string, pageSize?: number, pageNumber?: number, platform?: string, isDraftSession?: boolean): Promise<CurationPageResponse> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/landingPages/widgets`,
    params: {
      sessionId,
      limit: pageSize,
      offset: pageNumber,
      platform,
      version: "V2",
      usId,
      type: 'GENERIC',
      isDraftSession
    }
  }).then(res => res.data)
}

export function getGlobalCurationPageSections(usId: string, sessionId?: string, pageSize?: number, pageNumber?: number, platform?: string): any {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/landingPages/widgets`,
    params: {
      sessionId,
      limit: pageSize,
      offset: pageNumber,
      platform,
      version: "V2",
      usId,
      type: 'GENERIC'
    }
  }).then(res => res.data)
}