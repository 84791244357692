/** @jsxImportSource @emotion/react */

import Link from 'next/link';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Category, CategoryMappings, CuratedCategories } from '@vc-workspace/utils-schemas';
import { Typography } from '@vc-workspace/utils-typography';
import { getMappedCategories} from '@vc-workspace/utils-formatters';
import { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  containerStyles, 
  l0_category_styles, 
  l0SelectedCategoryStyles,
  dropdownContainerStyles,
  l1CategoryContainerStyles,
} from './category-navbar.module.style';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
import { CategorySelectionSources } from '@vc-workspace/utils-constants';
import { useRouter } from 'next/router';

const MAX_ITEMS_PER_COL = 17;

/* eslint-disable-next-line */
export interface CategoryNavbarProps {
  categories: Category[],
  primaryColor?: string,
  selectedL0Styles?: any,
  customDropDownContainerStyles?: any,
  l0Styles?: any,
  curatedCategories: CuratedCategories[],
  disableCuratedClick?: boolean
}

interface CategoryDropdownProps {
  categoryMapping: CategoryMappings[],
  customDropDownContainerStyles?: any,
  onCategorySelect: (selectedCategory: Category, parentCategory?: Category) => void
}

type Sections = CategoryMappings[]

function getSections(categoryMappings: CategoryMappings[]): Sections[] {
  let sections: Sections[] = [];

  if(categoryMappings.length <= 7) {
    return categoryMappings.map(item => [item]);
  }

  // Set first 7 sections
  sections = categoryMappings.slice(0, 7).map(item => [item]);

  categoryMappings.slice(7, 14).forEach((item, index) => {
    sections[6-index].push(item);
  })

  sections = sections.filter(section => section.length)

  return sections;
}

function CategoryDropdown(props: CategoryDropdownProps) {

  const categoryMappings = props.categoryMapping.sort((a, b) => b.childrens.length - a.childrens.length)

  const sections = getSections(categoryMappings);

  return (
    <div css={[dropdownContainerStyles, props.customDropDownContainerStyles ? props.customDropDownContainerStyles : null]}>
      <div style={{display: 'flex'}}>
      {
        sections.map((section, index) => (
          <div key={index} css={l1CategoryContainerStyles}>
            {
              section.map(categoryMap => (
                <>
                <a href={`/category/${categoryMap.category.slug}`}>
                  <Typography
                    type="Body1"
                    onClick={() => props.onCategorySelect(categoryMap.category)}
                    className='pt-6'
                    color="nero" semi_bold={!!categoryMap.childrens.length}>
                    {categoryMap.category.displayName}
                  </Typography>
                </a>
                <div>
                  { 
                    categoryMap.childrens.map(l2Category => (
                      <a href={`/category/${l2Category.slug}`}>
                        <Typography
                          key={l2Category._id}
                          type="Body1"
                          color="charcoal"
                          hover_color="nero"
                          onClick={() => props.onCategorySelect(l2Category, categoryMap.category)}
                          className="mt-3">
                          {l2Category.displayName}
                        </Typography>
                      </a>
                    ))
                  }
                </div>
                </>
              ))
            }
          </div>
        ))
      }
      </div>
    </div>
  )
}

export function CategoryNavbar(props: CategoryNavbarProps) {

  const [selectedCuratedCategory, setSelectedCategory] = useState<CuratedCategories | null>(null);
  const router = useRouter();

  useEffect(() => {
    if(selectedCuratedCategory) {
      document.body.classList.add("disable-scrolling")
    } else {
      document.body.classList.remove("disable-scrolling")
    }

    return () => {
      document.body.classList.remove("disable-scrolling")
    }
  }, [selectedCuratedCategory])

  if(!props.categories || !props.curatedCategories) {
    return null
  }

  let selectedCategoryMapping: CategoryMappings[] = [];

  selectedCuratedCategory?.categories.forEach(category => {
    selectedCategoryMapping =  [...selectedCategoryMapping, ...getMappedCategories(props.categories, category.categoryId, category.categoryLevel)]
  })

  function onCategorySelect(selectedCategory: Category, parentCategory?: Category) {

    sendAmplitudeData(AmplitudeEventNames.CATEGORY_INTERACTION, {
      source: CategorySelectionSources.NAVBAR,
      category_level: selectedCategory.level,
      name: selectedCategory.displayName,
      parent_l1_category: parentCategory && parentCategory.displayName,
      parent_l0_category: selectedCuratedCategory?.title
    })
    setSelectedCategory(null);
  }

  function onCuratedCategoryClick(category: CuratedCategories) {
    if(props.disableCuratedClick) return;
    window.location.href = `/p/${category.redirectionPage.slug}/${category.redirectionPage.usId}`;
  }

  return (
    <ClickAwayListener onClickAway={() => setSelectedCategory(null)}>
      <div css={containerStyles} onMouseLeave={() => setSelectedCategory(null)}>
        {
          props.curatedCategories.map((curatedCategory: CuratedCategories) => {
            return (
              <Typography
                key={curatedCategory.redirectionPage.usId}
                type={"Body2"}
                color={props.primaryColor ? props.primaryColor : "nero"}
                onClick={() => onCuratedCategoryClick(curatedCategory)}
                onMouseOver={() => setSelectedCategory(curatedCategory)}
                custom_style={[
                  props.l0Styles ? props.l0Styles : l0_category_styles, 
                  curatedCategory.redirectionPage.usId === selectedCuratedCategory?.redirectionPage.usId ? (props.selectedL0Styles ? props.selectedL0Styles : l0SelectedCategoryStyles) : null
                ]}
                inline bold>
                {curatedCategory.title}
              </Typography>
            )
          })
        }
        {
          selectedCuratedCategory ? (
            <CategoryDropdown categoryMapping={selectedCategoryMapping} onCategorySelect={onCategorySelect} customDropDownContainerStyles={props.customDropDownContainerStyles} />
          ) : null
        }
      </div>
    </ClickAwayListener>
  );
}
