
import { ReviewTemplateTypes } from "@vc-workspace/utils-constants";
import { PublicDisplayReviewSchema, ReviewSchema, ReviewTemplateSchema } from "@vc-workspace/utils-schemas";
import axios from "axios";

interface ReviewTemplateResponse {
  SELLER: ReviewTemplateSchema,
  PRODUCT: ReviewTemplateSchema
}

export type ReviewEntryPayloadSchema = {
  entryUuid: string,
  textValue?: string,
  selectedNumberValue?: number,
  images?: {document: string}[]
}

export interface ProductReviewRequestPayloadSchema {
  itemOrderUsId?: string,
  itemUsId: string,
  reviewTemplateUsId: string,
  entries: ReviewEntryPayloadSchema[]
}

interface GetShopReviewsResponse {
  totalReviewsCount: number;
  reviews: PublicDisplayReviewSchema[]
}

interface GetProductReviewsResponse {
  totalReviewsCount: number;
  reviews: PublicDisplayReviewSchema[]
}

export async function fetchReviewTemplates(templateType: ReviewTemplateTypes): Promise<ReviewTemplateResponse> {

  try {
    const res: any = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/reviews/template`,
      params: {
        reviewTemplateTypes: [templateType]
      }
    });

    return {
      SELLER: res.data.reviewTemplates.filter((item: ReviewTemplateSchema) => item.templateType === ReviewTemplateTypes.SELLER)[0],
      PRODUCT: res.data.reviewTemplates.filter((item: ReviewTemplateSchema) => item.templateType === ReviewTemplateTypes.PRODUCT)[0]
    };

  } catch (err: any) {
    return err?.response?.data.message;
  }
}

export function saveProductReview(payload: ProductReviewRequestPayloadSchema): Promise<ReviewSchema> {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/reviews/product`,
    data: payload
  }).then((res: any) => res.data.review);
}

export function getShopReviews(shopId: string, page: number, limit: number, includeAllReviews?: boolean): Promise<GetShopReviewsResponse> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/reviews/maker`,
    params: {
      shopId,
      limit,
      start: page * limit,
      sortOrder: "DESC",
      filter: includeAllReviews ? [1, 2, 3, 4, 5] : []
    }
  }).then(res => res.data)
}

export function getProductReviews(itemUsId: string, page: number, limit = 10): Promise<GetProductReviewsResponse> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/reviews/product`,
    params: {
      itemUsId,
      limit,
      start: page * limit,
      sortOrder: "DESC"
    }
  }).then(res => res.data)
}