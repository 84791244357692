import { MARKETPLACE_ACCESS_TOKEN, MARKETPLACE_USER_ID, VC_APP_GUEST_REFER_AND_EARN_KEY } from '@vc-workspace/utils-constants';
import { getKeyFromStorage, removeKeyFromStorage, setKeyInStorage } from '@vc-workspace/utils-helpers';
import axios from 'axios';
import { Address, IntlUserDataSchema, ReviewSchema, User, WalletSchema, WishlistEntryItemSchema } from '@vc-workspace/utils-schemas';
import { setAmplitudeUserId as sendIndievibeAmplitudeUserId } from '@vc-workspace/utils-indievibe-amplitude';
import { setAmplitudeUserId } from '@vc-workspace/utils-amplitude';

export type AddressCreate = {
  parentType: 'User';
  parent: string;
  phone: string;
  phoneDialCode: string;
  apartment: string;
  street: string;
  landmark: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  addresseeName: string;
  addressType: 'HOME' | 'OFFICE' | 'CUSTOM';
  addressCustomName?: string;
};

interface UpdateUserPayload {
  firstName?: string,
  lastName?: string
}

export type WishlistEntriesResponse = {
  totalCount: number,
  wishListItems: WishlistEntryItemSchema[]
}

export interface B2BCustomerResponse {
  b2bCustomerOnboardingDone: boolean;
  user: IntlUserDataSchema
}

export function getUserDetails(): Promise<User> {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/users/me`,
  })
    .then((res: any) => {
      setKeyInStorage(MARKETPLACE_USER_ID, res.data.user._id);
      setAmplitudeUserId(res.data.user._id);
      setKeyInStorage(MARKETPLACE_ACCESS_TOKEN, getKeyFromStorage(MARKETPLACE_ACCESS_TOKEN));
      removeKeyFromStorage(VC_APP_GUEST_REFER_AND_EARN_KEY);
      return res.data.user;
    })
    .catch(() => {
      removeKeyFromStorage(MARKETPLACE_ACCESS_TOKEN);
      return {};
    });
}

export function getIntlUserDetails(): Promise<B2BCustomerResponse> {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/b2b/customers`,
  })
    .then((res: any) => {
      setKeyInStorage(MARKETPLACE_USER_ID, res.data.user._id);
      sendIndievibeAmplitudeUserId(res.data.user._id);
      setKeyInStorage(MARKETPLACE_ACCESS_TOKEN, getKeyFromStorage(MARKETPLACE_ACCESS_TOKEN));
      return res.data;
    })
    .catch(() => {
      removeKeyFromStorage(MARKETPLACE_ACCESS_TOKEN);
    });
}

export function getUserAddresses() {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/addresses`,
  }).then((res: any) => res.data.addresses);
}

export function saveUserAddress(payload: AddressCreate) {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/addresses`,
    data: payload,
  }).then((res: any) => res.data.address);
}

export function editUserAddress(payload: Address) {
  return axios({
    method: 'put',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/addresses/${payload._id}`,
    data: payload,
  }).then((res: any) => res.data.address);
}

export function removeUserAddress(payload: Address) {
  return axios({
    method: 'delete',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/addresses/${payload._id}`,
  });
}

export async function addItemToWishlist(siaId: string) {

  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wishLists/default/entries`,
    data: {
      siaIds: [siaId]
    },
  }).then((res) => res.data);
}

export function fetchWishlistItems(page: number, limit: number): Promise<WishlistEntriesResponse> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wishLists/default/entries`,
    params: {
      start: page * limit ,
      limit
    }
  })
    .then((res) => res.data)
    .catch(() => ({totalCount: 0, wishListItems: []})); // eslint-disable-line no-console
};

export async function removeItemFromWishlist(siaId: string) {

  return axios({
    method: 'DELETE',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wishLists/default/entries`,
    params: {
      siaId
    }
  }).then(res => res.data).catch(console.log);

}

export const addBulkItemsToWishlist = (siaIds: string[]) => {
  return axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wishLists/default/entries`,
    data: {
      siaIds,
    },
  })
    .then((res) => res.data)
    .catch(() => ({}));
};

export async function getGuestReferral(){
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/referrals/generic`,
  }).then(res => res.data);
}
export async function getUserReferral(){
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/referrals/me`,
  }).then(res => res.data);
}
export function getWalletBalance(walletType?: string): Promise<{wallet: WalletSchema}> {
  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wallet/me?walletType=${walletType}`,
  }).then((res) => res.data);
}

export function updateUserDetails(payload: UpdateUserPayload): Promise<User> {

  return axios({
    method: 'put',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/users/me`,
    data: payload
  }).then((res: any) => res.data.user);
}

export function getOwnProductReview(itemUsId: string): Promise<ReviewSchema> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/reviews/product/self`,
    params: {
      itemUsId
    }
  }).then((res: any) => res.data.review).catch(() => ({}))
}

export function checkIfInWishlist(usId: string): Promise<boolean> {

  return axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_PREFIX}/v1/market/wishLists/check`,
    params: {
      itemUsId: usId
    }
  }).then((res: any) => res.data.added)
  .catch(() => false);
}