import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { appDownloadTooltipContainer, primaryDownloadButton, downloadStoreLinkCss, appDownloadTooltipContainerLight, appBannerStyles } from './app-banners-style.module';
import { Typography } from '@vc-workspace/utils-typography';
import LightTooltip from '../tooltips/light-tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { USER_VISIT_METADATA_KEY, VC_APP_GENERIC_DOWNLOAD_LINK, VC_APP_PLAY_STORE_LINK, VC_APP_STORE_LINK } from '@vc-workspace/utils-constants';
import { TailwindColors } from '@vc-workspace/utils-colors';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { LocalStorage, getUTMFromAttributionJSON, getUTMFromQuery, sanitiseArray } from '@vc-workspace/utils-helpers';
import { convertJsonToQueryString } from '@vc-workspace/utils-formatters';

function trackAmplitudeEvent(type, source) {
  sendAmplitudeData(AmplitudeEventNames.DOWNLOAD_LINK_CLICKED, {
    type: type,
    source: source
  });
}

export function trackOneLinkEvent(source) {
  sendAmplitudeData(AmplitudeEventNames.DOWNLOAD_LINK_CLICKED, {
    type: 'ONELINK',
    source: source || 'NAVBAR'
  });
}
export var DownloadAppTooltip = function DownloadAppTooltip(props) {
  return _jsx(LightTooltip, {
    title: _jsx("div", {
      className: "bg-white",
      children: _jsx(ScanQRDownload, {})
    }),
    children: _jsx("div", {
      css: props.lightBg ? appDownloadTooltipContainerLight : appDownloadTooltipContainer,
      children: _jsxs("div", {
        className: "flex gap-x-2",
        children: [_jsx("img", {
          src: props.lightBg ? '/_mp-images/mobile-device-black.svg' : '/_mp-images/mobile-device-white.svg',
          alt: "device"
        }), _jsx(Typography, {
          type: "Body1",
          color: "inherit",
          children: "Download App"
        })]
      })
    })
  });
};
export function ScanQRDownload(props) {
  return _jsxs("div", {
    className: "flex gap-x-3 rounded-xl items-center",
    children: [_jsx("img", {
      src: "/_mp-images/app-install-qr-code.svg",
      alt: "scan-qr",
      style: {
        width: '70px',
        height: '70px'
      }
    }), _jsxs("div", {
      className: "flex flex-col gap-y-1",
      children: [_jsx(Typography, {
        type: "SubHeading1",
        color: "inherit",
        bold: true,
        children: props.title ? _jsx(_Fragment, {
          children: props.title
        }) : _jsxs(_Fragment, {
          children: ["Scan the QR code to ", _jsx("br", {}), " download the app"]
        })
      }), _jsxs("div", {
        className: "flex flex-row gap-x-2",
        children: [_jsx(AppDownloadButton, {
          type: "android",
          source: "DESKTOP_NAVBAR"
        }), _jsx(AppDownloadButton, {
          type: "ios",
          source: "DESKTOP_NAVBAR"
        })]
      })]
    })]
  });
}
export function AppDownloadMobileBanner(props) {
  var _useState = useState(''),
      queryString = _useState[0],
      setQueryString = _useState[1];

  useEffect(function () {
    var attrArray = sanitiseArray(LocalStorage.getKey(USER_VISIT_METADATA_KEY));

    if (attrArray && attrArray.length) {
      var attrDetails = attrArray[attrArray.length - 1];
      var utmParams = getUTMFromAttributionJSON(attrDetails);

      var _queryString = convertJsonToQueryString(utmParams);

      if (props.context.query) {
        var routeQueryParamsString = convertJsonToQueryString(getUTMFromQuery(props.context.query));

        if (routeQueryParamsString) {
          _queryString = routeQueryParamsString;
        }
      }

      setQueryString(_queryString);
    }
  }, []);
  return _jsxs("div", {
    className: "flex gap-x-6 bg-white w-full mb-2 p-2 pl-4 border-solid border-b border-gray-200",
    css: appBannerStyles,
    children: [_jsx("div", {
      className: "absolute top-1 right-2",
      onClick: props.onClose,
      children: _jsx(CloseIcon, {
        fontSize: "small",
        htmlColor: "#737373"
      })
    }), _jsx("img", {
      src: "/_mp-images/app-download-banner.svg",
      alt: "device"
    }), _jsxs("div", {
      className: "flex flex-col",
      children: [_jsx(Typography, {
        type: "Body1",
        color: "inherit",
        bold: true,
        children: "Upto 55% OFF, exclusively on App"
      }), _jsx("div", {
        className: "flex flex-row justify-between mt-2 items-center",
        children: _jsx("a", {
          href: "".concat(VC_APP_GENERIC_DOWNLOAD_LINK, "?").concat(queryString),
          target: "_blank",
          rel: "noreferrer",
          onClick: function onClick() {
            return trackOneLinkEvent(props.source);
          },
          children: _jsx("button", {
            css: primaryDownloadButton,
            children: "Download App"
          })
        })
      })]
    })]
  });
}
export function AppDownloadButton(_ref) {
  var type = _ref.type,
      source = _ref.source;
  return _jsxs("div", {
    className: "flex gap-x-2",
    css: downloadStoreLinkCss,
    children: [_jsx("img", {
      src: "".concat(type === 'ios' ? '/_mp-images/app-store-logo.svg' : '/_mp-images/play-store-logo.svg'),
      alt: "play-store"
    }), _jsx("a", {
      href: type === 'android' ? VC_APP_PLAY_STORE_LINK : VC_APP_STORE_LINK,
      target: "blank",
      rel: "norefferer",
      onClick: function onClick() {
        return trackAmplitudeEvent(type, source);
      },
      children: _jsxs("div", {
        className: "flex flex-col cursor-pointer",
        children: [_jsx("span", {
          style: {
            fontSize: '8px'
          },
          children: "Download on"
        }), _jsx("span", {
          style: {
            fontSize: '16px',
            marginTop: '-5px'
          },
          children: type === 'ios' ? 'App Store' : 'Google Play'
        })]
      })
    })]
  });
}
export function DownloadAppBottomSheet(props) {
  var _useState2 = useState(''),
      queryString = _useState2[0],
      setQueryString = _useState2[1];

  useEffect(function () {
    var attrArray = sanitiseArray(LocalStorage.getKey(USER_VISIT_METADATA_KEY));

    if (attrArray && attrArray.length) {
      var attrDetails = attrArray[attrArray.length - 1];
      var utmParams = getUTMFromAttributionJSON(attrDetails);

      var _queryString2 = convertJsonToQueryString(utmParams);

      if (props.context.query) {
        var routeQueryParamsString = convertJsonToQueryString(getUTMFromQuery(props.context.query));

        if (routeQueryParamsString) {
          _queryString2 = routeQueryParamsString;
        }
      }

      setQueryString(_queryString2);
    }
  }, []);
  return _jsx(Modal, {
    onClose: props.onClose,
    open: props.open,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginTop: '-10px'
    },
    children: _jsxs("div", {
      className: "flex gap-x-6 bg-white w-full px-2 py-6 pl-4 outline-none relative rounded-lg",
      css: appBannerStyles,
      children: [_jsx("div", {
        className: "absolute top-2 right-3",
        onClick: props.onClose,
        children: _jsx(CloseIcon, {
          fontSize: "small",
          htmlColor: "#737373"
        })
      }), _jsx("img", {
        src: "/_mp-images/app-download-banner.svg",
        alt: "device"
      }), _jsxs("div", {
        className: "flex flex-col",
        children: [_jsx(Typography, {
          type: "SubHeading1",
          color: TailwindColors.grey[900],
          bold: true,
          children: "Download Vibecity App to start chatting with seller!"
        }), _jsx("div", {
          className: "flex flex-row justify-between mt-2 items-center w-full",
          children: _jsx("a", {
            href: "".concat(VC_APP_GENERIC_DOWNLOAD_LINK, "?").concat(queryString),
            target: "_blank",
            rel: "noreferrer",
            className: "w-full",
            children: _jsx("button", {
              css: primaryDownloadButton,
              className: "w-full py-3",
              children: "Download App"
            })
          })
        })]
      })]
    })
  });
}